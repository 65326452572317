/*
NOTE: This prevents the second scroll bar issue in some layouts and when bottomsheet is shown
For Admin sites, all scrolling should be done inside of panels, so this 'hack' shouldnt cause issues (but could)
*/

body {
    overflow-y: hidden;
}

layout {
    &.fp-full-height {
        height: 100%;
    }
}



header {
    md-toolbar {
        z-index: 3;
    }

    &.disabled {
        md-toolbar {
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.4);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 999999;
            }
        }
    }
}


footer {
    &.disabled {
        md-toolbar {
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.4);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 999999;
            }
        }
    }
}






view-international {
    .override-dropdown-sm {
        md-select-value {
            padding: 0px;
            min-height: initial;
            font-size: 89%;
            margin-bottom: -2px;
        }

    }


    md-card md-card-content {
        padding: 0;
    }
}




//=============================================================================
// List Exploration Start
/*
    EXPLORATION - Move away from cards
    Warning: ng-click directive on md-list-item oddly changes the markup
        significantly.  All the following overrides suppose ng-click is used!
*/
// psi = 'Plan, Services, International'

.plans-container {
    .psi-plans-img {
        width: 100%;
    }

    .psi-img-container {
        display: inline-block;
    }
}



//GENERAL SPACING & POSITIONING IMPROVEMENTS
md-list.fp-list {

    .title {
        //color: rgb(21, 119, 164) !important;
        //font-weight: 500 !important;
    }

    .md-avatar {
        border-radius: initial;
    }

    //Contains both img and text
    .md-list-item-inner {
        margin: 16px 0px;
        align-items: flex-start;
        min-height: initial !important;

        .md-list-item-text {
            //border: 1px solid red;
            margin: initial;

            h3 {
                white-space: normal !important;
                font-weight: 500;
            }

            ul {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.010em;
                margin: 0 0 0 0;
                line-height: 1.3em;
                color: rgba(0,0,0,0.54);
            }

            .fp-list-content {
                //border: 1px solid darkgoldenrod;
                margin-left: 8px;
                margin-right: 8px;
            }

            .fp-list-actions {
                //border: 1px solid gold;
                margin-top: 8px;
            }
        }
    }

    .ul-spacing ul {
        margin-left: -10px !important;
    }

}


md-list.fp-current-product {
    .product-status-active {
        color: rgba(0, 120, 0, .54) !important;
        margin-top: 8px !important;
    }
}

md-list.fp-price {
    .ui.mini.statistic {
        margin: 2px 0 0 0;
        line-height: initial;

        .value {
            font-size: 0.9rem !important;
            text-align: right;
            color: rgba(0, 0, 0, 0.67);
        }

        .label {
            font-size: 0.6em;
            text-align: right;
            color: rgba(0, 0, 0, 0.67);;
        }
    }

    .md-list-item-inner .md-list-item-text h3 {
        margin-bottom: 8px;
        margin-right: 6px;
    }
}


md-list.fp-w-list-actions {
    .md-list-item-inner {
        margin-bottom: 10px;
    }
}

md-list.fp-cardify {
    padding: 0px;
    margin-top: 1px;
    background: #FFF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12); //md-whiteframe-1dp
}

// List Exploration End
//=============================================================================



//NOTE: Since product-price is a wrapper component around Semantic UI Statistic, then
// also set inline-flex to wrapped div to have same behavior.
product-price {
    display: inline-flex;
}


.fp-md-header {
    fp-logo {
        svg {
            width: 108px;
            vertical-align: text-bottom;
        }
    }
}

user-feed-dropdown,
user-feed-list {
    display: block;
}

.user-feed-badge[data-badge]:after {
    content:attr(data-badge);
    position:absolute;
    top: 3px;
    right: 4px;
    font-size:.7em;
    background: red;
    color:white;
    width:18px;height:18px;
    text-align:center;
    line-height:18px;
    border-radius:50%;
    box-shadow:0 0 1px #333;
}

.fpop-media-layout-gt-xs {
    .header-notifications-container {
        width:500px;
        max-height:500px;
        overflow-y: scroll;

        .item {
            /* margin: 15px 0px; */
        }
    }
}

.fpop-media-layout-xs {
    .header-notifications-container {
        width:200px;
        max-height:500px;
        overflow-y: scroll;

        .item {
            /* margin: 15px 0px; */
        }
    }
}

.fp-md-sidenav {
    fp-logo {
        svg {
            width: 108px;
            /*vertical-align: text-bottom;*/
        }
    }
}

md-list-item.fp-name-email {
    h3 {
        font-weight: bold !important;
    }
}

// Better than .md-display-1
.fp-page-title {
    margin: 0px 0px 0px 16px;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;

    line-height: 32px;
}



[ui-view="layout"] {

    dashboard,
    account {
        height: 100%;
        background-color: rgba( 0,0,0,0.1 );

        md-content,
        md-content.md-default-theme {
            background-color: transparent;
        }


        md-tabs,
        [md-whiteframe],
        .md-whiteframe {
            background-color: #fff;
        }

    }

}

[ui-view="main"] {
    background-color: #f8f8f8;
}





/* MD Override */
.layout-padding > * {
    padding: initial;
}

/* MD Override from Angular Material Website */
md-input-container {
    .hint {
        position: absolute;
        left: 2px;
        right: auto;
        bottom: 7px;
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        color: grey;
    }
}



/*
    Helpers
*/
.not-found {
    font-style: italic;
    color: grey;
    height: 50px;
}

.hiddenDebugText {
    color: rgba(255, 255, 255, .5);
    font-size: 12px;
    font-style: italic;
    margin: 0px;
}


ul.detailBullets {
    margin: 5px 0px;

    strong:after {
        content: ": "
    }
}




/*
    Angular Material Typography - md-as-subheader
*/
.md-display-1 {
    &.md-as-subheader {
        display: block;
        color: rgba(0, 0, 0, 0.67);
        margin: 0px;
        font-size: 22px;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: +0.005em;
    }
}

.md-headline {
    &.md-as-subheader {
        display: block;
        color: rgba(0, 0, 0, 0.67);
        margin: 0px;
        font-size: 17px;
        font-weight: 500;
        line-height: 1em;
        letter-spacing: +0.005em;
    }
}

.md-title {
    &.md-as-subheader {
        display: block;
        color: rgba(0, 0, 0, 0.67);
        margin: 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1em;
        letter-spacing: +0.005em;
    }
}





/* SIDENAV (Exceptions + Improvements) */
.fp-md-sidenav {

    .md-tall {
        height: 100px;
        min-height: 100px;
        max-height: 100px;
    }

    .logo {
        vertical-align: text-top;
        width: 125px;
        margin: 0px 0 0 0;
    }

    md-icon {
        margin-left: 0px;
        margin-right: 5px;
    }

    md-list-item > a {
        margin: 0px;
    }
}


/* HEADER (Exceptions + Improvements) */
.fp-md-header {

    .logo {
        vertical-align: text-top;
        width: 150px;
    }

    .menuItems {
        margin-left: 10px;
        margin-right: 10px;

        .md-button {
            background-color: rgba(0, 0, 0, 0);
            color: inherit;

            margin-left: 2px;
            margin-right: 2px;
            line-height: 27px;
            min-width: 77px;
            font-size: 11.5px;

            md-icon {
                color: inherit;

                height: 24px;
                width: 24px;
                margin-top: 4px;
            }

            &:not([disabled]):hover {
                background-color: rgba(255, 255, 255,0.2);
            }

        }
    }

    .links {
        margin: 0px 15px;

        .list .item .header a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: rgba(255, 255, 255, 0.9);
            }
        }
    }

}


user-account-dropdown {

    .static-title {
        max-width: 10em;
    }

    &.inside-sidenav {
        // margin-left: -27px;
    }

    md-menu > .md-button {
        text-transform: none;

	    &:not([disabled]):hover {
            background-color: rgba(255, 255, 255,0.2);
        }
    }

    md-list {
        md-list-item.md-2-line{
            min-height: 56px;

            .md-list-item-text {
                text-align: left;

                h3 {
                    color: white;
                }

                p {
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }
    }


}



/* FOOTER (Exceptions + Improvements) */
.fp-md-footer {
    padding: 8px;
    text-align: center;

    & > div {
        padding: 3px;
    }

    .copyright {
        font-weight: bold;
        color: #fff;
    }

    .links {
        .list .item .header a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: rgba(255, 255, 255, 0.9);
            }
        }
    }

}



/*
    MD Tabs
*/
md-tabs.fp-tabs {
    md-tabs-wrapper {
        background-color: #fafafa;
        border-radius: 3px;
    }
}


/*
    Usage Component
*/
.usage-items {
    .usage-item {
        margin-bottom: 30px;
        /*border: 1px solid darkblue;*/

    }
}

.more-info {
    text-align: center;
}
/*
    Circle Usage Componenet
*/
.svg circle  {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #EEE;
  stroke-width: .20em;
  display:inline-block;
  vertical-align:top;
  border:1px solid grey;
}



/*
    Toolbars
*/

.fpop-btnbar-sm {

    button {
        background-color: transparent;
        border: 0;
        line-height: 1;


        md-icon {
            border: 0;
            line-height: 1;
            width: 16px;
            height: 16px;
        }

    }

}


/*
    Icons
*/

.icn-light path {
    fill: #fff;
}

.icn-dark path {
    fill: #333;
}





md-card.fp-phone-entity-card {

    max-width: 400px;

    md-card-title {
        padding: 12px;
    }

    ul {
        list-style-type: square;
        //margin-left: 38px;
    }

    .open-slot {
        color: lightgray;
    }

}

md-card.fp-card-friend-invite {

    .height-initial {
        height: initial;
        margin-top: -3px;
    }
}

md-card.fp-card-earn-history {

    .email {
        color: grey;
        padding-top: 8px;
    }
}

/*
    Overview Billing Cards
*/
md-card.card-billing {

    .billing-item {
        height: 151px;
    }
}




/* ========== Settings - CSS START ========== */
md-card.fp-card-settings {
    min-height: 320px;

    md-card-title {
        padding-top: 16px;

        md-card-actions {
            margin-top: 0px;
            margin-right: 0px;

            button.md-button {
                margin-right: 0px !important;
            }
        }
    }
}



md-card.fp-voice-settings {
    .md-list-item-inner, md-list-item.md-no-proxy, md-list-item .md-no-style {
        .fpop-media-layout-xs & {
            padding: 0px;
        }
    }

}

/* ========== Settings - CSS END ========== */




/*
    Product Cards (Exceptions + Improvements)
*/
md-card.fp-md-product-card {

    margin-bottom: 15px;

    .md-title {
        margin: 0 8px 8px 0;
        color: rgb(21,119,164);
    }


    .content-price {
        text-align: center;
    }

    .btn-details {
        background-color: rgba(0, 100, 200, 0.8);
        color: white;
    }
    .btn-details:not([disabled]):hover {
        background-color: rgba(0, 100, 200, 0.96);
    }
    .btn-details[disabled] {
        color : rgb(187, 187, 187);
        background-color: rgba(220, 220, 230, 0.96);
    }


    .btn-activate {
        background-color: rgba(0, 170, 0, 0.8);
        color: white;
    }
    .btn-activate:not([disabled]):hover {
        background-color: rgba(0, 170, 0, 0.96);
    }
    .btn-activate[disabled] {
        color : rgb(187, 187, 187);
        background-color: rgba(220, 230, 200, 0.96);
    }

}



/* Very similar to fp-md-product-card & intentionally different CSS */
md-card.fp-md-product-detail-card {

    .product-image {
        max-width: 125px;

        &.large {
            margin-right: 8px;
            margin-bottom: 8px;
        }

    }

    .md-headline {
        margin: 0 0 5px 0;
        /*color: rgb(2,119,189);*/
    }

    .content-price-status {
        padding-bottom: 15px;
    }

    .product-status-active {
        color: #080;
        font-size: 80%;
        text-align: right;
    }

    .product-limited-time {
        color: #008;
        font-size: 80%;
        text-align: right;
    }

    .btn-activate {
        width: 200px;
        background-color: rgba(0, 170, 0, 0.8);
        color: white;
    }

    .btn-activate:not([disabled]):hover {
        background-color: rgba(0, 170, 0, 0.96);
    }

    .btn-activate[disabled] {
        color : rgb(187, 187, 187);
        background-color: rgba(220, 230, 200, 0.96);
    }

    blockquote {
        text-align: center;
        font-style: italic;
        font-size: 20px;

        background-color: rgba(249,249,249,1);
        border-left: 5px solid rgb(170,221,244);
        color: rgb(21,119,164);
        padding: 15px;
        margin: 0px 20px 4px 0px;

        .fpop-media-layout-xs & {
            margin-bottom: 20px;
        }

        @media screen and (max-width: 600px) {
            margin-right: 0px;
            margin-left: 0px;
        }

        .by {
            font-style: normal;
            font-weight: bold;
            font-size: 70%;
        }
    }

    .downgrade-info {
        color: #AAA;
        font-size: 80%;

        a {
            color: #77F;
        }
    }

    .downgrade-info-apparent { //darker and bigger
        color: #888;
        font-size: 85%;

        a {
            color: #77F;
        }
    }

    .sub-product-description {
        margin: 10px;
        background-color: #fdfdfd;

        img {
            width: 80px;
            height: 80px;
            margin: 5px 10px 5px 0px;
        }
    }

    /* Overrides */
    .ui {

        &.items .item {

        }

        &.image {
            width: 80px;
        }

    }

    /* MD Overrides */
    .md-avatar {
        border-radius: 0%;
    }


}


.service-sub-product {
    background: #FAFAFA;
    box-shadow: 0 0 0 1px #e2e2e2, 0 2px 0 0 #21BA45, 0 1px 3px 0 #D4D4D5;
    border-radius: 3px;
    width: 250px;
    margin: 8px;

    product-price {
        margin-top: 16px;
    }

    .content {
        padding: 16px;
    }

    .actions {
        padding: 8px;

        // &:not(.layout-column) .md-button:not(.md-icon-button) {
        //     margin: 0 4px;
        // }
    }
}




.confirmation-panels-dialog {

    .toolbar-transition {
        transition: all .7s;
    }

    md-tabs-wrapper {
        display: none;
    }

    h2 {
        margin: 0 initial;
    }

    md-dialog-content {
        max-width: 550px;
    }

    .narrowWidth {
        max-width: 275px;
    }

    .standardContent, .processingContent, .resultContent {
        min-height: 300px;
        min-width: 500px;

        .fpop-media-layout-xs & {
            min-width: initial;
        }
    }

    .carousel-content {
        min-width: 500px;
        max-width: 500px;

        .fpop-media-layout-xs & {
            min-width: initial;
        }

        &--fixed-height {
            min-height: 300px;
        }
    }



    .resultContent {
        text-align: center;
    }

    .fineprint {
        max-width: 500px;
    }



    .downgrade-warning {
        p {
            color: #ffffff;
        }
    }
}




/*
    Phone Setup
*/
.settings-phone-setup-new {

    .important-fine-print {
        color: red;
    }

    .phone-number-container {
        margin: 0px 8px 16px 0px;
    }
}

.settings-phone-setup-transfer {

    .carrier-examples {
        img {
            display: block;
            margin: 4px auto;
            width: 120px;
        }
    }

    /* Override */
    md-input-container {
        margin: 8px 0px;
    }

    .transfer-status {
        color: rgb(212, 57, 88);

        .transfer-status-label {
            font-weight: bold;
            letter-spacing: -.01em;
        }
    }

    md-dialog {
        max-height: 90%;
    }
}

.settings-phone-setup-gvn {

    .country-dropdown {
        width: 155px;
    }

    .radio-button-container {
        margin: 8px 0px;
    }

    .fp-animate-show-hide {
        transition: all linear 0.4s;

        &.ng-hide {
            transition: all linear 0s;
            opacity: 0;
        }
    }


}


/*
    Notification Headers
*/

notify-header,
simple-banner {
    div.ui.message {
        margin: 1em auto 0 auto !important;
        width: 94% !important;
    }

}

general-notify-header,
notify-header {
    md-icon {
        width: 48px;
        height: 48px;

        &[md-svg-icon="main-lg:icn-warning-48"] {
            color: #f44336;
        }
    }
}



order-status-notif {
    // padding-top: 10px;

    a {
        background-color: rgb(232, 232, 52) !important;

        svg {
            fill: rgb(34, 166, 227);
        }

         &:hover svg {
             fill: #000;
         }

         animation: pulseYellowButton 4.5s linear infinite;
         animation-fill-mode: both;
         animation-direction: alternate;

    }
}


/*
    Offer Wall
*/
.offer-wall-offers {
    column-count: 3;
    column-gap: 0;

    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    -o-column-break-inside: avoid;
    -ms-column-break-inside: avoid;
    column-break-inside: avoid;


    & > div {
        box-sizing: border-box;
        padding: 8px;

        display: inline-block;
        width: 100%;

        & > md-card {
            margin: 0;
        }

    }

    .fpop-media-layout-gt-md & {
        column-count: 5;
    }
    .fpop-media-layout-md & {
        column-count: 4;
    }
    .fpop-media-layout-sm & {
        column-count: 2;
    }
    .fpop-media-layout-xs & {
        column-count: inherit;
    }
}


/*
    Devices
*/
manage-devices {
    .devices-list {
        md-card-content {
            p.md-title {
                color: #000;
            }
        }
    }
    .devices-single-item {
        md-card-actions {
            .md-button {
                font-size: 12px;
            }
        }
    }

    /* Overriide red label for x-editable text */
    .editable-empty, .editable-empty:focus {
        text-decoration: none;
        color: #428bca;
        border-bottom: dashed 1px #428bca;
    }

    .editable-click:hover, a.editable-click:hover {
        text-decoration: none;
        color: #2a6496;
        border-bottom-color: #2a6496;
    }

    /* Make this global and remove */
    .disabled-content {
        position: relative;
        pointer-events: none;
        opacity: 0.6;
        .device-name {
            font-style: italic;
        }
    }

    .disabled-content:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(40, 40, 40, 0.7);
        z-index: 10;
    }
}

// .fpop-media-layout-xs {
//     .devices-single-item {
//         md-card-actions {
//             .md-button {
//                 font-size: 11px;
//                 padding: 0;
//                 margin: 0;
//                 max-width: 110px;
//                 white-space: nowrap;
//                 overflow: hidden;
//                 text-overflow: ellipsis;
//             }
//         }
//     }
// }


// http://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
.ff-overflow-bug-fix {
    min-height: 0;
    min-width: 0;
}

.tether-element, .tether-element:after, .tether-element:before, .tether-element *, .tether-element *:after, .tether-element *:before {
    box-sizing: border-box; }

.tether-element:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0,0,0,0.5); /*dim the background*/
}

.tether-element {
    display: block;
    visibility: hidden;
    z-index: 200;
}

.tether-element.tether-open {
    display: block;
}


/* too add overlay */
.fpop-ref {
    background: rgba( 0, 0, 0, 0.5 );
}





/*
    MD: Mobile Friendly Headlines
*/
.md-display-2 {
    .fpop-media-layout-sm &.mobile-friendly {
        font-size: 36px;
        line-height: 51px;
    }

    .fpop-media-layout-xs &.mobile-friendly {
        font-size: 27px;
        line-height: 38px;
    }
}

.md-display-1 {
    .fpop-media-layout-sm &.mobile-friendly {
        font-size: 27px;
        line-height: 32px;
    }

    .fpop-media-layout-xs &.mobile-friendly {
        font-size: 20px;
        line-height: 24px;
    }
}

.md-headline {
    .fpop-media-layout-sm &.mobile-friendly {
        font-size: 22px;
        line-height: 28px;
    }

    .fpop-media-layout-xs &.mobile-friendly {
        font-size: 19px;
        line-height: 22px;
    }
}


/*

    Proximity Search CSS

*/

.proximity-container md-virtual-repeat-container {
  position: relative;
  display: block;
  width: 100%;
  height: 60vh;
  border: solid 1px #ccc;
  padding: 10px;
}

.qiubo-logo {
    height: 75px;
    background: url(https://web-cdn.freedompop.com/resources/my/QiuboLogo-1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
    background-size: contain;
}

.payment-details-table {
    .margin-left {
        &-15 {
            margin-left: 15px;
        }
    }
}

.payment-recovery-fees-modal {
    .desc {
        max-width: 600px;
    }

    table {
        border: none !important;

        tfoot {
            tr {
                th {
                    background-color: inherit!important;
                }
            }
        }
    }
}

.recovery-info-icon {
    color: #c5c5c5;
}



// Mock Modal Settings
.mock-modal {

    md-toolbar {

        &.md-medium-tall {

            .md-toolbar-tools {
                max-height: 100%;
                height: 100%;
                padding-left: 2em;

                h1, h2, h3 {
                    font-weight: bold;
                }
            }

            .ui.label.large {

                span {
                    transform: rotate( -45deg ) scale( 0.8, 0.8 ) translate( 0, 10px );
                    display: block;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                }

                &:after {
                    border-top: 88px solid #0000;
                    border-right: 88px solid #0000;
                    border-bottom: 0em solid #0000;
                    border-left: 0em solid #0000;
                    border-top-color: inherit;
                    height: 88px !important;
                    width: 88px;
                }
            }
        }

    }


}


.fpop-media-layout-xs {
    .mock-modal {

        md-toolbar {

            &.md-medium-tall {

                .md-toolbar-tools {

                    h1, h2, h3 {
                        font-size: 80%;
                    }
                }

            }
        }
    }
}








